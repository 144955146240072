import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import Navbar from './nav/Navbar';
import Home from './pages/home/Home';
import Websites from './pages/websites/Websites';
import Contact from './pages/contact/Contact';
import  {  ThemeProvider  }  from  "./themeContext";
import {lightTheme, darkTheme} from './helpers/Theme'
import Footer from './footer/Footer';



function App() {
  return (
    <ThemeProvider >

      <Router>
        <div className="App">
          <Navbar />
          <div style={{paddingTop: '60px'}}>
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/websites' element={<Websites />} />
            <Route exact path='/contact' element={<Contact />} />
          </Routes>
          <Footer />
          </div>
        </div>
      </Router>


      </ThemeProvider>


  );
}

export default App;

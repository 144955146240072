import React from 'react'
import styles from './contactform.module.scss'
import { ThemeContext } from "../../themeContext";
import emailjs from 'emailjs-com';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope} from "@fortawesome/free-solid-svg-icons"






function ContactForm() {


    const { toggle } = React.useContext(ThemeContext);


    const sendEmail = (e) => {
		e.preventDefault();
	
		emailjs.sendForm('service_nbaznpm', '010101_Template_id', e.target, 'K0CvYnvf774TqbG36')
		.then((result) => {
			console.log(result.text);
		}, (error) => {
			console.log(error.text);
		});
		e.target.reset();
		};




  return (


<form onSubmit={sendEmail} method="POST"

>
<div className={ !toggle ? `${styles.formInner}` : `${styles.formInner} ${styles.dark}`}
      

>
<h1>Contact</h1>
    <div className={styles.ctForm__input}>
        <div className={styles.ctForm__inputItems}>
            <input type="text" name="first-name" size="40" aria-required="true" aria-invalid="false" placeholder='Name*'></input>
            {/* <input type="text" name="last-name" size="40" aria-required="true" aria-invalid="false" placeholder='Last Name*'></input> */}
        
        </div>
   
         <div className={styles.ctForm__inputItems}>
             <input type="text" name="email"  placeholder="Email Address*" size="40" aria-required="true" aria-invalid="false" />
             {/* <input type="text" name="phone"  placeholder="Phone Number*" size="40" aria-required="true" aria-invalid="false" /> */}
         </div>

         {/* <div className={styles.ctForm__inputItems}>
            <div>
                 <label></label>
                 <input
                          type="date"
                          min="2019-01-01"
                          max="2022-12-31"
                          value={enteredDate}
                          onChange={dateChangeHandler}
                            />
            </div>
          <div>



           <label></label>
           <input
             type="date"
             min="2019-01-01"
             max="2022-12-31"
             value={enteredDate}
             onChange={dateChangeHandler}
                    />
            </div>


                    
                      

</div> */}

<div className={styles.ctForm__inputItems}>
<textarea name="message" cols="40" rows="10"  aria-invalid="false" placeholder="Message" />


</div>

                    
</div >


  <div className={styles.buttonWrap}>
    <button type="submit" className={ !toggle ? `${styles.button}` : `${styles.button} ${styles.dark}`}>
    SEND<FontAwesomeIcon icon={faEnvelope} />
    </button>
  </div>

  </div>
</form>

    

  )
}

export default ContactForm
import React from 'react'
import styles from './wavesvg.module.scss'

import { motion } from "framer-motion"

function WaveSvg() {
  return (
    <motion.svg className={styles.container}>
        <motion.path 
        d="M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z" 
        stroke="rgb(145, 115, 235)"
        fill="rgb(145, 115, 235)"
        initial="matrix(-1 0 0 1 1600 0)"
        fill-rule="evenodd"
        />



    </motion.svg>
  )
}

export default WaveSvg
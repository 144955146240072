import React from 'react'
import styles from './mobiletoggler.module.scss'
import { motion } from "framer-motion"


const Path = (props) => (
    < motion.path 
        strokeWidth="5" 
        fill="transparent" 
        strokeLinecap="round" 
        {...props} />
    );

    const transition = { duration: 0.3 };

function MobileToggler({TogglerHandler, show, stroke}) {


   

  return (
    <div className={styles.mobileToggler}onClick={TogglerHandler}>
    <svg width="26" height="23" veiwbox="0 0 26 23">
        <Path animate={show} 
        initial={false} 
        stroke={stroke}
        variants={{

            closed: { d: "M 2 2.5 L 26 2.5", },
            open: { d: "M 3 19.5 L 23 2.5",}
        
        }} 
        transition={transition}  
        />

        <Path 
        d="M 2 9.423 L 26 9.423"
        stroke={stroke}
        animate={show}
        initial={false}
        
        variants={{
            closed: { opacity: 1 },
            open: { opacity: 0 },
        }}
            transition={transition}  
            />

        <Path 
        animate={show} 
        initial={false} 
        stroke={stroke}
        variants={{
             closed: { d: "M 2 16.346 L 26 16.346",},
             open: { d: "M 3 2.5 L 23 19.346",}
        
        }} transition={transition}  
        />
    </svg>
    </div>
  )
}

export default MobileToggler
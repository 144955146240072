import React from 'react'
import styles from './darktoggler.module.scss'
// import './darktoggler.scss'
import { ThemeContext } from "../../themeContext";
import { motion, } from "framer-motion"





const containerVariants = {
  dark: {


    //background: "linear-gradient(to bottom, #000 20%, #00025b",
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
      delay: .01,
    },
    backgroundColor: "#1A202C",

  },
  light: { backgroundColor: "#76A5FF" }
};

const childVariants = {
  dark: {
    y: 0,
    transition: {
      // type: "tween"
      type: "spring",
      mass: 0.4,
      damping: 8,
    }
  },
  light: {
    y: "-100px"
  }
};










function Switch({ isOn, ...props }) {
  const className = `switch ${isOn ? "on" : "off"}`;
  const style = { 
    height: "32px",
    width: "32px",
    borderRadius: "50px",
  }

  return (

      <motion.div animate style={style} className={className} {...props} />

  );
}


function DarkToggler() {

    const { toggle, toggleFunction } = React.useContext(ThemeContext);


 

    const size = 50;
    



  return (

    <div className={styles.container} onClick={toggleFunction}>
      
      <motion.div 
      // className={`${styles.toggler} ${styles.on}${styles.off}`} 

      // className={ `${styles.toggler}`+ (toggle ? `${styles.on}` : `${""}`)}

      className={ !toggle ? `${styles.toggler}` : `${styles.toggler} ${styles.dark}`}

        
      //   style={toggle ? { 
      //   justifyContent: "flex-end",
        
       
      //   } 
      //   : 
      //   {
      //     justifyContent: "flex-start",
      //     border: "none",
        
      //   } 
      
      // }
      variants={containerVariants}
      initial={toggle ? `dark` : `light` }
      exit={toggle ? `dark` : `light` }
      // animate={toggle}
      animate={toggle ? `dark` : `light` }
      >
        <motion.div variants={childVariants}></motion.div>
        <motion.div variants={childVariants}></motion.div>
        <motion.div variants={childVariants}></motion.div>
        <motion.div variants={childVariants}></motion.div>
        <motion.div variants={childVariants}></motion.div>

        <Switch   
          layout
          animate={toggle ? `dark` : `light` }
          variants={{
            dark: {
              boxShadow: "inset 0px 7px white, inset 0px 7px 1px 1px white",
              rotate: 90,
              background: "transparent"
            },
            light: {
              boxShadow: "inset 0px 0px white, inset 0px 0px 0px 0px white",
              rotate: 90,
              background: "#fff"
            }
          }}
          initial={false}
        
        />
      </motion.div>

    </div>
  )
}

export default DarkToggler
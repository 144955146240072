

export const mobileLinks = [

    {
      id:0,
      title: "Home",
      to: "/",
    },
    {
      id:0,
      title: "Websites",
      to: "/websites",
    },
    {
      id:1,
      title: "Contact",
      to: "/contact",
    }
]


export const websiteinfo = [

    {
        id: 0,
        title: 'LEDLites USA',
        description: "Using Wordpress, with Javascript and PHP. A full E-Commerce Site.",
        bgImage: '/images/websites/ledlites.jpeg',
     

        tagsimage: ['/images/icons/wpIcon.png', '/images/icons/jsIcon.png', '/images/icons/wooIcon.png' ],
        tStack1: ['React', '/images/icons/react.png'],
        source: 'https://github.com',
        link: 'https://ledlitesusa.com',

        tags: [

          {
            id: "0",
            stack:"Wordpress",
            img: "/images/icons/wpIcon.png"
          },
          {
            id: "1",
            stack:"React",
            img: "/images/icons/react.png"
          },
          {
            id: "2",
            stack:"Wordpress",
            img: "/images/icons/wpIcon.png"
          },
        ],

      },


      {
        id: 1,
        title: 'Full Amazon Clone',
        description:"Full E-Commerce using firebase and Stripe api",
        bgImage: '/images/websites/amazon.jpg',
        source: 'https://github.com/AdamMacintyre/amazon-clone-filterRedux',
        link: 'https://amazon-clone-cyan.vercel.app',

        tags: [

          {
            id: "0",
            stack:"React",
            img: "/images/icons/reactIcon.png"
          },
          {
            id: "1",
            stack:"Firebase",
            img: "/images/icons/firebase.png"
          },
          {
            id: "2",
            stack:"Stripe",
            img: "/images/icons/stripe.png"
          },
        ],

      },

      {
        id: 2,
        title: 'Multi Chip Display',
        description:"Flight Instrumentation",
        bgImage: '/images/websites/mdiCardDisplay.png',
        source: 'https://google.com',
        link: 'https://mdisplay.com',

        tags: [

          {
            id: "0",
            stack:"React",
            img: "/images/icons/reactIcon.png"
          },
          {
            id: "1",
            stack:"Framer Motion",
            img: "/images/icons/framer.png"
          },
          {
            id: "2",
            stack:"Stripe",
            img: "/images/icons/wpIcon.png"
          },
        ],

      },
      {
        id: 2,
        title: 'Real Estate Agency',
        description:"Mobile Responsive Real Estate website",
        bgImage: '/images/websites/realestateagency.png',
        source: 'https://github.com/AdamMacintyre/realestate-agency',
        link: 'https://realestateteam.vercel.app/',

        tags: [

          {
            id: "0",
            stack:"React",
            img: "/images/icons/reactIcon.png"
          },
          {
            id: "1",
            stack:"Framer Motion",
            img: "/images/icons/framer.png"
          },
          {
            id: "2",
            stack:"Stripe",
            img: "/images/icons/wpIcon.png"
          },
        ],

      },


]
import React from 'react'
import styles from './websites.module.scss'
import WebsiteCard from '../../components/WebsiteCard/WebsiteCard';
import { websiteinfo } from '../../helpers/index';
import { ThemeContext } from "../../themeContext";



const Websites = () => {
  const { toggle } = React.useContext(ThemeContext);
  return (
    <div className={styles.container}>
      <div className={ !toggle ? `${styles.inner}` :`${styles.inner} ${styles.dark}`}>
        {websiteinfo.map((i) => {
          return (
            <WebsiteCard
              id={i.id}
              bgImage={i.bgImage}
              title={i.title}
              desc={i.description}
              tags={i.tags}
              source={i.source}
              link={i.link}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Websites
import React from 'react'
import styles from './footer.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import initFontAwesome from "../helpers/initFontAwesome";
import { motion } from "framer-motion"
import { ThemeContext } from "../themeContext";
import Pyramid from './Pyramid'
import WaveSvg from './WaveSvg';

initFontAwesome();
const Footer = () => {

  const { toggle, toggleFunction } = React.useContext(ThemeContext);
  // style={toggle ? { background: "linear-gradient(to bottom, #000 20%, #00025b"} : {background: "linear-gradient(to bottom, deepskyblue 20%, #dfedff)"}}>


  return (
    <motion.div className={styles.container} 
    style={toggle ? { background: "rgb(32, 33, 36)", borderTop:"1px solid rgba(145, 115, 235,0.5)"} : {background: "rgb(228, 230, 235)", borderTop:"1px solid rgba(145, 115, 235,0.5)"}}>

        <div className={styles.iconWrap}>
            <motion.span 
            className={ !toggle ? `${styles.footerLinkWrap}` : `${styles.footerLinkWrap} ${styles.dark}`}
            whileHover={{scale: 1.2, background:"transparent"}}
            >
            <FontAwesomeIcon icon={['fab', 'linkedin']} style={toggle ? { color: "rgb(228, 230, 235)"} : {color: "rgb(32, 33, 36)"}}  />
            </motion.span>


            <motion.span className={styles.footerLinkWrap}
            whileHover={{scale: 1.2, background:"transparent"}}
            >
            <FontAwesomeIcon icon={['fab', 'github']} style={toggle ? { color: "rgb(228, 230, 235)"} : {color: "rgb(32, 33, 36)"}}/>
            </motion.span>
        
        </div>

        {/* <Pyramid /> */}
        {/* <div className={styles.ground} style={toggle ? { background: "rgb(162, 150, 85)"} : {background: "#d8c977"}}/>
        <div className={styles.pyramid1right} style={toggle ? { background: "#b0b0b0"} : {background: "#dddddd"}}></div>
        <div className={styles.pyramid1left} style={toggle ? { background: "#d5d5d5"} : {background: "#efefef"}}></div> */}
    
    
        <div className={styles.waveWrap}>
        <WaveSvg />
        <WaveSvg />
    
        </div>
       
    </motion.div>

  )
}

export default Footer
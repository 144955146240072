import React from 'react'
import { motion } from "framer-motion";
import { ThemeContext } from "../../themeContext";
// Word wrapper
const Wrapper = (props) => {
  // We'll do this to prevent wrapping of words using CSS
  return <span className="word-wrapper">{props.children}</span>;
};

// Map API "type" vaules to JSX tag names
const tagMap = {
  paragraph: "p",
  heading1: "h1",
  heading2: "h2"
};



function WavyTextInner(props, color, classname) {


  const { toggle } = React.useContext(ThemeContext);



  // Framer Motion variant object, for controlling animation
  const item = {
    hidden: {
      y: "200%",
     

      transition: { 
        ease: [0.455, 0.03, 0.515, 0.955], duration: 0.85, 
        type: "spring",
        damping: 12,
        stiffness: 200,
        }

    },

    visible: {
      y: 0,
     
      
      transition: { 
        ease: [0.455, 0.03, 0.515, 0.955], duration: 0.75, 
        type: "spring",
        damping: 12,
        stiffness: 200,
       }
    }
  };

  //  Split each word of props.text into an array
  const splitWords = props.text.split(" ");

  // Create storage array
  const words = [];

  // Push each word into words array
  for (const [, item] of splitWords.entries()) {
    words.push(item.split(""));
  }

  // Add a space ("\u00A0") to the end of each word
  words.map((word) => {
    return word.push("\u00A0");
  });

  // Get the tag name from tagMap
  const Tag = tagMap[props.type];


  const styles = {
    
  }
  return (
    <Tag>
    {words.map((word, index) => {
      return (
        // Wrap each word in the Wrapper component
        <Wrapper key={index}>
          {words[index].flat().map((element, index) => {
            return (
              <span
                style={{
                  overflow: "hidden",
                  display: "inline-block",

                }}
                key={index}
              >
                <motion.span 
                  // classname={!toggle ? `${styles.wavytext}` : `${styles.wavytext} ${styles.dark}`}
                  style={toggle ? {color: "rgb(145, 115, 235)", display: "inline-block"} : {color: "rgb(43, 35, 68)", display: "inline-block"}}
                  variants={item}
                >
                  {element}
                </motion.span>
              </span>
            );
          })}
        </Wrapper>
      );
    })}
    {/* {} */}
  </Tag>
  )
}

export default WavyTextInner
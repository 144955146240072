import React, { useState } from 'react'
import styles from './pyramid.module.scss'
import { motion, } from "framer-motion"
import cx from 'classnames'

function Pyramid() {

    const [ nightShow, setNightShow ] = useState(true)

  return (
    <>
    <motion.div 
    className={styles.tableau}    
    style={nightShow ? { background: "linear-gradient(to bottom, #000 20%, #00025b"} : {background: "linear-gradient(to bottom, deepskyblue 20%, #dfedff)"}}
    >

        <div className={styles.ground} style={nightShow ? { background: "rgb(162, 150, 85)"} : {background: "#d8c977"}}/>
        <div className={styles.pyramid1right} style={nightShow ? { background: "#b0b0b0"} : {background: "#dddddd"}}></div>
        <div className={styles.pyramid1left} style={nightShow ? { background: "#d5d5d5"} : {background: "#efefef"}}></div>


    </motion.div>


</>
  )
}

export default Pyramid
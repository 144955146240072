import React from 'react'
import styles from './websitecard.module.scss'
import { ThemeContext } from "../../themeContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBarcode, faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons"
import GridLineSvg from '../../components/svg/GridLineSvg';

function WebsiteCard({id, bgImage, title, desc, tags, source, link}) {

  const { toggle } = React.useContext(ThemeContext);

  return (
    <div key={id} 
    className={ !toggle ? `${styles.container}` : `${styles.container} ${styles.dark}`}

    >
          {/* <GridLineSvg />  */}
      <div className={ !toggle ? `${styles.inner}` : `${styles.inner} ${styles.dark}`} >

       
        
        <div className={styles.image} style={{backgroundImage: `url(${bgImage})`}} >
        <header className={styles.title}>
          <h1 >
            {title}
          </h1>
        </header>
        </div>




        <div className={styles.tags}>
        {tags.map( (t) => {
				  return (
            <div key={t.id}>
              <h4>{t.stack}</h4>
              <img src={t.img} />
            </div>
          );

			  })}
        </div>


        
        <div className={styles.desc}>
          <h3>{desc}</h3>
        </div>



        <div className={styles.cardLinks}>

          <a href={source}>
          <FontAwesomeIcon icon={faBarcode}/>
          <h2>View Source Code</h2>
          </a>
      

          <a href={link}>
          <FontAwesomeIcon icon={faUpRightFromSquare}/>
          <h2>Visit Site</h2>
          </a>

        </div>




      </div>
    </div>
  )
}

export default WebsiteCard
import React from 'react'
import { Link } from 'react-router-dom';
import styles from './navbar.module.scss'
import { ThemeContext } from "../themeContext";
import { motion, AnimatePresence } from "framer-motion"
import DarkToggler from './toggler/DarkToggler';
import MobileToggler from './toggler/MobileToggler';

import Breakpoint from '../components/MediaQuery/Breakpoint'



import MyuseBreak from '../components/MyuseBreak'
import NavFade from './NavFade'

import '../global.css';
import {mobileLinks} from '../helpers/index'







function Navbar() {

  const { toggle } = React.useContext(ThemeContext);

  const [links, setLinks] = React.useState(null);

  
  const TogglerHandler = (event) => {
      setTimeout(()=>{ 
        (setLinks(!links))
    }, 300)       
  };

  const MobileSizer = ({children, at}) => {
     if (!at) {
       console.error("<Breakpoint>: must specify a breakpoint for the `at` prop.");
     }
     const { active } = MyuseBreak();
     return active === at ? children : null
  }
 



  const pathcolor = "#064420"
  

  

  return (

    //<NavFade>
    <nav className={ !toggle ? `${styles.navbar}` : `${styles.navbar} ${styles.dark}`} 
    style={toggle ? { background: "#202124" } : {background: "#E4E6EB"}}
    
    >

      {/* <Breakpoint at={`${"lg" , "md" }`} size={size}>
        <div className={styles.shownList}>
          <Link to="/"><span>Home</span></Link>
          <Link to="/websites"><span>Websites</span></Link>
          <Link to="/contact"><span>Contact</span></Link>
        </div>
      </Breakpoint> */}

      <MobileSizer at="lg" >



        <motion.div className={styles.logo}
         variants={hovervariants}
         initial={{scale:1,}}
         whileHover={{
           scale:2,
           scale: 1.3,
          
         
         
         }}
         transition={{
             type: "spring",
             duration: 0.18,
             stiffness: 500,
             //delay: .03,
             //when: "beforeChildren",
             //staggerChildren: 0.1,
         }}
         exit={{scale:0,}}
        >

          <Link to="/"><h1 style={toggle ? { color: "#E4E6EB" } : {color: "#202124"}}><span>A</span>M</h1></Link>
        </motion.div>

        <div className={styles.shownList}>
        {mobileLinks.slice(1,3).map((i)=>{
          return(
            <Link key={i.id} to={i.to} style={toggle ? { color: "#E4E6EB" } : {color: "#202124"}} >

            <motion.li 
              variants={hovervariants}
              initial={{scale:1,}}
              whileHover={{
                scale:2,
                scale: 1.3,
               
              
              
              }}
              transition={{
                  type: "spring",
                  duration: 0.18,
                  stiffness: 500,
                  //delay: .03,
                  //when: "beforeChildren",
                  //staggerChildren: 0.1,
              }}
              exit={{scale:0,}}
              
              >
                <span>
                  {i.title}
                </span>
            </motion.li>
            </Link>

          )
        })}
        </div>
      </MobileSizer>






      <AnimatePresence >
          {links && (

        <motion.div className={styles.motionMobileMenu}
           initial={{ opacity: 0, 
            x: "-100vw", 
            transform: "translateX(0em)",
            y: "46.5%",
            
          }}
           animate={{ opacity: 1, 
             x: ['100vw',  '0vw'],
             x: 0, 
             //transform: "translateX(-100%)"
            y: "46.5%",
          }}
          exit={{ x: "-100vw",}}

          transition={{type:"spring",
          duration: 1.3,
          stiffness: 300,
          damping: 17,
          delay: 0.04,
          restDelta: 2
        }}
          // exit={{ x: -100, opacity: 0, transition: { duration: 0.5 } }}
          // transition={{
          //   ease: [0.03, 0.01, 0.01, .01],
          //   duration: 0.3,

          // }}

        >
        <div className={styles.hiddenList}>
        <div className={ !toggle ? `${styles.hiddenList__inner}` :`${styles.hiddenList__inner} ${styles.dark}`} onClick={() => setLinks(!links)}>

        {mobileLinks.map((i)=>{
          return(
            <li key={i.id}>
              <Link to={i.to} style={toggle ? { color: "#E4E6EB" } : {color: "#202124"}} >
                <span>
                  {i.title}
                </span>
              </Link>
            </li>
          )
        })}
   
    
  
      </div>
      </div>
      
      </motion.div>

      )}
      </AnimatePresence>
      

          <Breakpoint at="xs">
          <MobileToggler 
            TogglerHandler={TogglerHandler}
            show={links ? "open" : "closed" }
            stroke={toggle ?  "rgb(145, 115, 235)"  : "rgb(67, 6, 138)"}
            />
          </Breakpoint>


   
    <DarkToggler />
  </nav>

  //</NavFade>
  )
}


const hovervariants = {

  initial:{
    scale:0,
  },
  hover: {
    // x: 50,
     //x: [10, 20, 10,],
     scale: [1.3, 1, 1.3],
      originX: 0,
      transition:{
        type: "spring",
        duration: 0.18,
        //delay: .03,
        //when: "beforeChildren",
        //staggerChildren: 0.1,
    }

  },

  exit: {
    scale: 1,
  }

}

export default Navbar
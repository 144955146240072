import React from 'react'
import { motion } from "framer-motion";

const svgVariants = {
    hidden:{},
    visible: {
        
        transition: {duration: 1}
    }
}

const pathVariants = {
    hidden:{
        opacity:0,
        pathLength: 0,
    },
    visible:{
        opacity:1,
        pathLength: 1,
        

        transition: {
            duration: 2,
            ease: "easeInOut"
        }

    }
}



function Svg1({stroke, fill, fill2, stroke2}) {

    

    const styles = {
        width: "100vw",
        position: "absolute",
        bottom: 0,
        background: "",
        height: "233px"
    }

  return (

        // background: url("data:image/svg+xml, ") bottom/100% no-repeat fixed;
            <motion.svg variants={svgVariants} style={styles}  xmlns='http://www.w3.org/2000/svg'  fill-rule='evenodd' 
            initial="hidden"
            animate="visible"
            >
                <motion.path
                fill={fill2}
                //fill='%23fa6762'
                d='M1280 0c-167.463 0-449.312 114.933-639.815 114.933C449.68 114.933 173.4 0 0 0v233h1280V0z'
                initial="hidden"
                animate="visible"
                variants={pathVariants}
                stroke={stroke2}
                stroke-miterlimit= "10"
                stroke-width= "2"
                />

                <motion.path
                fill={fill}
                d='M1280 140.13C1112.537 140.13 830.503 42 640 42S173.4 140.13 0 140.13v93h1280v-93z'
                variants={pathVariants}
                stroke={stroke}
                />


            </motion.svg>



  )
}

export default Svg1
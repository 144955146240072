import React, { useState, useEffect} from 'react'
import styles from './home.module.scss'
import { ThemeContext } from "../../themeContext";
import {lightTheme, darkTheme} from '../../helpers/Theme'
import cx from 'classnames'
import WavyTextPopin from '../../components/WavyTextPopin/WavyTextPopin';
import { websiteinfo } from '../../helpers/index';
import WebsiteCard from '../../components/WebsiteCard/WebsiteCard';
import Svg1 from '../../components/svg/Svg1';
import Svg2 from '../../components/svg/Svg2';
import Svg3 from '../../components/svg/Svg3';
import ContactForm from '../../components/ContactForm/ContactForm';
import { motion, useAnimation} from "framer-motion"
import {useInView} from 'react-intersection-observer'


function Home() {
  const { toggle } = React.useContext(ThemeContext);

  const {ref, inView} = useInView({
    threshold: 0.5
  });
  const animation = useAnimation();

  useEffect(() => {
    if(inView){
        // animation.start("animate")({
        //   x: 0,
        //   opacity: 1,
        //   transition:{
        //     type: 'spring', duration: 1, bounce: 0.3,  when: "beforeChildren",staggerChildren: 0.1,delay: .01,
        //   }
        // });

        animation.start("animate")
    }

    if(!inView){
      //   animation.start("initial")({ 
      //   opacity: 0,
      //   // x: i % 2 === 0 ? -50 : 50,
      //   x: "-100vw",
      //   y: "50%",
      // })

      animation.start("initial")
    }

    console.log("use effect", inView);
    }, [inView]);


    // const useHasBeenViewed = () => {
    //   const [ref, inView] = useInView();
    //   const prevInView = useRef(false);
    //   const hasBeenViewed = prevInView.current || inView;
    //   useEffect(() => {
    //     prevInView.current = inView;
    //   });
      
    //   return [hasBeenViewed, ref];
    // }

  return (
    <div className={styles.container} style={toggle ? { background: "#242526" } : {background: "#E4E6EB"}}>
      
      <section className={cx(styles.section, styles.one)}>
      <div className={cx(styles.innerContent, styles.one)}>
      <WavyTextPopin />
      </div>
      
      <Svg1 
    
      stroke={toggle ?  "rgba(67, 6, 138, 0.7)"  : "rgba(67, 6, 138, 0.9)"}
      stroke2={toggle ?  "rgba(67, 6, 138, 0.7)"  : "rgba(145, 115, 235, 0.6)"}
      fill={toggle ?  "#202124"  : "rgba(67, 6, 138, 0.9)"}
      fill2={toggle ?  "#202124"  : "rgba(145, 115, 235, 0.6)"}
      />
   
      </section>

      <section className={cx(styles.section, styles.two)}  ref={ref}>
        <div className={cx(styles.innerContent, styles.two)}>


      <motion.div className={styles.motionCardWrap} animate={animation} variants={parent}>
        {websiteinfo.map((i, key) => {
          return (

      
          <motion.div viewport={{ once: true }} variants={child}
              // initial={{
              //   opacity: 0,
              //   x: i % 2 === 0 ? -50 : 50,
              //   y: -50,

              // }}
            
              // transition={{
              //   duration: 0.5,
              //   delay: i * 0.3,
            
              // }}


              >
              <WebsiteCard 
                id={i.id}
                bgImage={i.bgImage}
                title={i.title}
                desc={i.description}
                tags={i.tags}
                source={i.source}
                link={i.link}
                key={key}
                />
              </motion.div>

            )
          })}
          </motion.div>
        </div>

        
      
      <Svg2 
      stroke={toggle ?  "rgba(67, 6, 138, 0.7)"  : "rgba(67, 6, 138, 0.9)"}
      stroke2={toggle ?  "rgba(67, 6, 138, 0.7)"  : "rgba(145, 115, 235, 0.6)"}
      fill={toggle ?  "#202124"  : "rgba(67, 6, 138, 0.9)"}
      fill2={toggle ?  "#202124"  : "rgba(145, 115, 235, 0.6)"}
      />
      </section>



      <section className={cx(styles.section, styles.three)}>
      <div className={cx(styles.innerContent, styles.three)}>

      <ContactForm />

      </div>

  

      <Svg3
      stroke={toggle ?  "rgba(67, 6, 138, 0.7)"  : "rgba(67, 6, 138, 0.9)"}
      stroke2={toggle ?  "rgba(67, 6, 138, 0.7)"  : "rgba(145, 115, 235, 0.6)"}
      fill={toggle ?  "#202124"  : "rgba(67, 6, 138, 0.9)"}
      fill2={toggle ?  "#202124"  : "rgba(145, 115, 235, 0.6)"}
      />
      </section>
    </div>
  )
}

const parent = {

  animate: {
 

    transition: {
      when: "beforeChildren",
      staggerChildren: 0.4,
      delay: .01,
    
    },
  },

  initial:{
 
  }


}

const child = {

  animate:{
    opacity: 1,
    x: 0,
  },

  initial:{
    opacity: 0,
     x: "-100vw",
    y: "50%",
  }

}

export default Home
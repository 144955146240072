import React from 'react'
import { motion } from "framer-motion";


const svgVariants = {
    hidden:{},
    visible: {
        
        transition: {duration: 1}
    }
}

const pathVariants = {
    hidden:{
        opacity:0,
        pathLength: 0,
    },
    visible:{
        opacity:1,
        pathLength: 1,
        

        transition: {
            duration: 2,
            ease: "easeInOut"
        }

    }
}




function Svg2({stroke, fill, fill2, stroke2}) {

    const styles = {
        width: "100vw",
        position: "absolute",
        top: "0",
        right: "0",
        left: "0",
        bottom: '0',
        height: "100vh",
        background: ""
    }


  return (
    // width='1280' height='233'
    <motion.svg variants={svgVariants} style={styles}  xmlns='http://www.w3.org/2000/svg'  fill-rule='evenodd' 
    initial="hidden"
    animate="visible"
    >
        <motion.path
        fill={fill}
        //fill='%23fa6762'
        d='M140.515 611.116C-12.61 495.728-45.706 281.379 66.592 132.353 178.892-16.673 394.06-43.941 547.185 71.446c153.125 115.388-83.89 141.804-196.188 290.83-112.299 149.026-57.357 364.228-210.482 248.84z'
        initial="hidden"
        animate="visible"
        variants={pathVariants}
        stroke={stroke}
        stroke-miterlimit= "10"
        stroke-width= "2"
        />

        <motion.path
        fill={fill2}
        d='M427 533.716c143.042 0 259-60.441 259-135 0-74.558-190.587-12.488-333.629-12.488-143.041 0-184.371-62.07-184.371 12.488 0 74.559 115.958 135 259 135z'
        variants={pathVariants}
        stroke={stroke2}
        />


    </motion.svg>
// data:image/svg+xml,%3Csvg width='686' height='644' xmlns='http://www.w3.org/2000/svg'%3E %3Cg fill='%23fa4f4a' fill-rule='evenodd'%3E %3Cpath d='' opacity='.8'/%3E %3Cpath d='' opacity='.6'/%3E %3C/g%3E %3C/svg%3E") center/auto 100% no-repeat fixed

  )
}

export default Svg2
import React from 'react'
import { motion } from "framer-motion";
import WavyTextInner from './WavyTextInner';
import styles from './wavytextpopin.module.scss'
import { ThemeContext } from "../../themeContext";

function WavyTextPopin() {

  const { toggle } = React.useContext(ThemeContext);

  const placeholderText = [
    { type: "heading1", text: "Welcome" },
    {
      type: "heading2",
      text: "Please Scroll Down To View More Info About My Websites"
    }
  ];

  const container = {
    visible: {
      transition: {
        staggerChildren: 0.025
      }
    }
  };


  return (
  <motion.div
    initial="hidden"
    animate="visible"
    variants={container}
    
  >

  <div className={styles.container} >
  {placeholderText.map((item, index) => {
    return(
    <WavyTextInner 
    {...item} key={index} 
    // color={toggle ?  "#E4E6EB"  : "#202124"}

    
    
    />

    ) 
  })}
  </div>


  </motion.div>
  )
}

export default WavyTextPopin
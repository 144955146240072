import React from 'react'
import ContactForm from '../../components/ContactForm/ContactForm'
import { ThemeContext } from "../../themeContext";
import cx from 'classnames'
import styles from './contact.module.scss'

const Contact = () => {
  const { toggle } = React.useContext(ThemeContext);
  return (
    <div className={cx(styles.container, styles.main)} style={toggle ? { background: "#242526" } : {background: "#E4E6EB"}}>

      <div className={styles.inner}>
      <ContactForm />

      </div>


   
 



        
    </div>
  )
}

export default Contact
import React from 'react'
import { motion } from "framer-motion";

const svgVariants = {
    hidden:{},
    visible: {
        
        transition: {duration: 1}
    }
}

const pathVariants = {
    hidden:{
        opacity:0,
        pathLength: 0,
    },
    visible:{
        opacity:1,
        pathLength: 1,
        

        transition: {
            duration: 2,
            ease: "easeInOut"
        }

    }
}


function Svg3({stroke, fill}) {

    const styles = {
        width: "100vw",
        position: "absolute",
        top: "0",
        right: "0",
        left: "0",
        bottom: '0',
        height: "100vh",
        background: ""
    }


  return (
    // width='1280' height='233'
     <motion.svg variants={svgVariants} style={styles}  xmlns='http://www.w3.org/2000/svg'  fill-rule='evenodd' 
    initial="hidden"
    animate="visible"
    >
        <motion.path
        fill={fill}
        //fill='%23fa6762'
        d='M0 0h1280L0 433z'
        initial="hidden"
        animate="visible"
        variants={pathVariants}
        stroke={stroke}
        stroke-miterlimit= "10"
        stroke-width= "2"
        />

      


    </motion.svg>
  )
}
        //background: url("data:image/svg+xml,%3Csvg width='1280' height='433' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='' fill='%23fa4f4a ' fill-rule='evenodd'/%3E %3C/svg%3E ") top/100% no-repeat fixed;

export default Svg3
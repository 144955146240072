import useMediaQuery from './MediaQuery/useMediaQuery';


export default function MyuseBreak(){
    // const breakpoints = {
    //   isXs: useMediaQuery("(max-width: 640px)"),
    //   isSm: useMediaQuery("(min-width: 641px) and (max-width: 768px)"),
    //   isMd: useMediaQuery("(min-width: 769px) and (max-width: 1024px)"),
    //   isLg: useMediaQuery("(min-width: 1025px)"),
    //   active: "xs"
    // };


    const breakpoints = {
      isSm: useMediaQuery("(min-width: 200px) and (max-width: 639px)"),
      isLg: useMediaQuery("(min-width: 640px)"),
      active: "xs"
    };

    // if (breakpoints.isXs) breakpoints.active = "xs";
     if (breakpoints.isSm) breakpoints.active = "sm";
    // if (breakpoints.isMd) breakpoints.active = "md";
     if (breakpoints.isLg) breakpoints.active = "lg";
    return breakpoints;


  }